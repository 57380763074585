// TableComponent.js
import React from 'react';
import Table from 'react-bootstrap/Table';

const WeekTableComponent = ({ data }) => {
  //let netProfitTotal = 0;
  let totalWeeksPlayed = data.length;
  /*
  for (var i = 0; i < data.length; i++) {
    let currentNetProfit = parseFloat(data[i].net_profit);
    netProfitTotal = parseInt(netProfitTotal + currentNetProfit);
  }
  */
  //console.log(data.length);
  //console.log("Current Net Profit: " + netProfitTotal + " at " + (data.length) + " rounds");

  return (
    <>
      <div className='grid-2-col nav'>
        <h4>Weekly Report</h4>
        <div>Weeks: {totalWeeksPlayed} </div>
      </div>

      <div className="table-container shadow border">
        <Table>
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Rounds</th>
              <th>Stakes</th>
              <th>Payouts</th>
              <th>Gross</th>
              <th>Net</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td>{item.roundsPlayed}</td>
                <td>{item.totalStake}</td>
                <td>{item.totalPayouts}</td>
                <td>{item.totalGrossProfit}</td>
                <td>{item.totalNetProfit}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default WeekTableComponent;
