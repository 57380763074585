// App.js

import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Dash from './Dash';

// import Dashboard from './components/Dashboard';
// import VerticalTabs from './components/VerticalTabs';
import { UserData } from './components/Data';
import './App.css';
//import database from './firebase-config';
import { database, auth, signUp, signIn, signOut } from './firebase-config';
// Styling files
import './css/TableComponent.css';
import './css/ChartComponent.css'; // Import the CSS file for styling
import './Chat.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  const [dataUsers, setDataUsers] = useState([]);
  const [userGrowthPercentage, setUserGrowthPercentage] = useState('');
  const [dataRecentTranscations, setDataRecentTranscations] = useState([]);
  const [dataAffiliates, setDataAffiliates] = useState([]);
  const [dataRoundResults, setDataRoundResults] = useState([]);
  const [dataRoundResultsWeek, setDataRoundResultsWeek] = useState([]);
  const [dataRoundResultsMonth, setDataRoundResultsMonth] = useState([]);
  const [dataNumberOfUsers, setDataNumberOfUsers] = useState(0);
  const [dataStakes, setDataStakes] = useState(0);
  const [stakeGrowthPercentage, setStakeGrowthPercentage] = useState('');
  const [dataPayouts, setDataPayouts] = useState(0);
  const [payoutGrowthPercentage, setPayoutGrowthPercentage] = useState('');
  const [dataProfit, setDataProfit] = useState(0);
  const [profitGrowthPercentage, setProfitGrowthPercentage] = useState('');
  const [totalAffiliates, setTotalAffiliates] = useState(0);
  const [activeAffiliates, setActiveAffiliates] = useState(0);
  const [paidCommissions, setPaidCommissions] = useState(0);
  const [unpaidCommissions, setUnpaidCommissions] = useState(0);

  // Function to calculate net profit for different time intervals
  function calculateNetProfit(data, interval) {
    const netProfitsMap = {};

    // Convert object to array of values
    const entries = Object.values(data);

    entries.forEach(entry => {
      const entryDate = new Date(entry.timestamp);
      let label;
      if (interval === 'daily') {
        label = entryDate.toLocaleDateString(); // Day as label
      } else if (interval === 'weekly') {
        const weekNumber = getWeekNumber(entryDate);
        label = `Week ${weekNumber}`; // Week number as label
      } else if (interval === 'monthly') {
        label = entryDate.toLocaleString('default', { month: 'long' }); // Month name as label
      }

      if (!netProfitsMap[label]) {
        netProfitsMap[label] = 0;
      }
      netProfitsMap[label] += parseFloat(entry.net_profit);
    });

    const labels = Object.keys(netProfitsMap);
    const netProfits = Object.values(netProfitsMap);

    return { labels, netProfits };
  }

  // Function to get week number of a date
  function getWeekNumber(date) {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const week = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    return week;
  }

  /*
  // Usage
  const netProfitDaily = calculateNetProfit(tableData, 'daily');
  const netProfitWeekly = calculateNetProfit(tableData, 'weekly');
  const netProfitMonthly = calculateNetProfit(tableData, 'monthly');
 
  console.log("Net Profit (Daily):", netProfitDaily);
  console.log("Net Profit (Weekly):", netProfitWeekly);
  console.log("Net Profit (Monthly):", netProfitMonthly);
  */

  const ReadRecentTransactionsDataFun = () => {
    let ref = database.ref("recent_transactions");
    const recentTransactionsData = [];
    ref.once("value", snapshot => {
      const data = snapshot.val();
      const dataArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));

      dataArray.forEach(item => {
        recentTransactionsData.push({
          id: item.id,
          action: item.action,
          amount: item.amount,
          phoneNumber: item.phoneNumber,
          resultCode: item.resultCode === 0 ? 'Success' : 'Failed',
          resultDesc: item.resultDesc,
          transactionDate: item.transactionDate,
          username: item.username
        });
      });
      setDataRecentTranscations(recentTransactionsData.reverse());
      //console.log(recentTransactionsData);
    })
  }

  const ReadUsersDataFun = () => {
    let ref = database.ref("users");
    const userData = [];
    
    ref.once("value", snapshot => {
        const data = snapshot.val();
        const dataArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
        
        // Create a lookup table to map referralcode to username
        const referralLookup = {};
        dataArray.forEach(item => {
            referralLookup[item.referralcode] = item.username;
        });
        
        // Process each user and map referredby to the corresponding username
        dataArray.forEach(item => {
            userData.push({
                id: item.id,
                username: item.username,
                phone: item.phone,
                createdon: item.createdon,
                referralcode: item.referralcode,
                referredby: referralLookup[item.referredby] || null // Map referredby to username
            });
        });

        // Sort userData based on the createdon field
        userData.sort((a, b) => {
            const parseDate = dateString => {
                if (!dateString) return new Date(0); // Handle undefined or null date strings
                const [date, time] = dateString.split(' - ');
                if (!date || !time) return new Date(0); // Handle improperly formatted strings
                const [day, month, year] = date.split('/').map(Number);
                const [hours, minutes, seconds] = time.split(':').map(Number);
                return new Date(year, month - 1, day, hours, minutes, seconds); // Month is 0-indexed
            };

            return parseDate(a.createdon) - parseDate(b.createdon);
        });

        // Modify userData to only include the date part in createdon
        const formattedUserData = userData.map(user => {
            const [date] = user.createdon.split(' - '); // Extract the date part
            return { ...user, createdon: date };
        });

        // Get the current date and date 7 days ago
        const currentDate = new Date();
        const sevenDaysAgoDate = new Date();
        sevenDaysAgoDate.setDate(currentDate.getDate() - 7);

        // Filter userData to get the number of users created in the last 7 days
        const usersLast7Days = formattedUserData.filter(user => {
            const userCreatedDate = new Date(user.createdon.split('/').reverse().join('-')); // Convert 'dd/mm/yyyy' to 'yyyy-mm-dd'
            return userCreatedDate >= sevenDaysAgoDate;
        });

        const numberOfUsersLast7Days = usersLast7Days.length;

        // Calculate growth percentage
        const previousTotalUsers = formattedUserData.length - numberOfUsersLast7Days;
        const growthPercentage = previousTotalUsers > 0
            ? ((numberOfUsersLast7Days / previousTotalUsers) * 100).toFixed(2)
            : numberOfUsersLast7Days > 0
                ? 100
                : 0;

        // Set growth percentage
        setUserGrowthPercentage(`${growthPercentage}%`);

        // Set data number of users
        setDataNumberOfUsers(userData.length);
        setDataUsers(formattedUserData.reverse());

        // Calculate cumulative users for chart data
        const { labels, cumulativeUsers } = calculateCumulativeUsers(formattedUserData);

        setChartDataUsers({
            labels: labels,
            datasets: [{
                label: "Cumulative Users",
                data: cumulativeUsers,
                backgroundColor: 'blue', // Color for cumulative users
            }]
        });
    });
};


  const ReadUsersAffiliatesDataFun = () => {
    let ref = database.ref("users");
    const userData = [];
    ref.once("value", snapshot => {
      const data = snapshot.val();
      const dataArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
      dataArray.forEach(item => {
        userData.push({
          id: item.id,
          username: item.username,
          phone: item.phone,
          createdon: item.createdon,
          referralcode: item.referralcode,
          referredby: item.referredby
        });
      });
      // Sort userData based on the createdon field
      userData.sort((a, b) => {
        const parseDate = dateString => {
          if (!dateString) return new Date(0); // Handle undefined or null date strings
          const [date, time] = dateString.split(' - ');
          if (!date || !time) return new Date(0); // Handle improperly formatted strings
          const [day, month, year] = date.split('/').map(Number);
          const [hours, minutes, seconds] = time.split(':').map(Number);
          return new Date(year, month - 1, day, hours, minutes, seconds); // Month is 0-indexed
        };
        return parseDate(a.createdon) - parseDate(b.createdon);
      });
      // Modify userData to only include the date part in createdon
      const formattedUserData = userData.map(user => {
        const [date] = user.createdon.split(' - '); // Extract the date part
        return { ...user, createdon: date };
      });
      // Calculate Invitees and Commissions
      const affiliateDataPromises = formattedUserData.map(user =>
        CalculateAffiliateData(user.referralcode)
      );
      Promise.all(affiliateDataPromises)
      .then(affiliateDataArray => {
        let totalInvitees = 0;
          let totalPaidCommissions = 0;
          let totalUnpaidCommissions = 0;
          const topAffiliates = formattedUserData.map((user, index) => {
            const { invitees, commission } = affiliateDataArray[index];
            totalInvitees += invitees;
            // Assuming all earnings are due initially
            const paidCommission = 0;
            const unpaidCommission = commission;
            totalPaidCommissions += paidCommission;
            totalUnpaidCommissions += unpaidCommission;
            return {
              username: user.username,
              invitees,
              earnings: commission.toFixed(2),
              paid: paidCommission.toFixed(2),
              due: unpaidCommission.toFixed(2),
              refCode: user.referralcode,
              joined: user.createdon
            };
          }).sort((a, b) => b.invitees - a.invitees).slice(0, 10);
          setDataAffiliates(topAffiliates);
          setTotalAffiliates(formattedUserData.length);
          setActiveAffiliates(totalInvitees);
          setPaidCommissions(totalPaidCommissions.toFixed(2));
          setUnpaidCommissions(totalUnpaidCommissions.toFixed(2));
      })
      .catch(error => {
        console.error("Error calculating affiliate data:", error);
      });
    });
  };

  const CalculateAffiliateData = (referralCode) => {
    return new Promise((resolve, reject) => {
      const usersRef = database.ref("users");
      usersRef.once("value")
      .then(snapshot => {
        const invitees = [];
        snapshot.forEach(childSnapshot => {
          const userData = childSnapshot.val();
          if (userData.referredby === referralCode) {
            invitees.push(userData.id);
          }
        });

        const commissionPromises = invitees.map(inviteeId => CalculateCommision(inviteeId));
        Promise.all(commissionPromises)
          .then(commissions => {
            const totalCommission = commissions.reduce((total, { commission }) => total + commission, 0);
            resolve({ invitees: invitees.length, commission: totalCommission });
          })
          .catch(error => {
            reject(error);
          });
      })
      .catch(error => {
        reject(error);
      });
    });
  };

  const CalculateCommision = (ref_id) => {
    return new Promise((resolve, reject) => {
      const stakesRef = database.ref(`stakes/${ref_id}`);
      stakesRef.once("value")
      .then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let totalStakedAmount = 0;
          if (Array.isArray(data)) {
            data.forEach(val => {
              totalStakedAmount += parseInt(val.bet_amount, 10);
            });
          } else if (typeof data === 'object') {
            Object.keys(data).forEach(key => {
              const val = data[key];
              totalStakedAmount += parseInt(val.bet_amount, 10);
            });
          }
          const commission = totalStakedAmount * 0.02;
          resolve({ totalStakedAmount, commission });
        } else {
          resolve({ totalStakedAmount: 0, commission: 0 }); // No data found
        }
      })
      .catch(error => {
        reject(error);
      });
    });
  };

  const ReadUsersDataFunDaily = () => {
    setDataUsers([]);
    let ref = database.ref("users");
    const userData = [];
    
    ref.once("value", snapshot => {
        const data = snapshot.val();
        const dataArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
        
        // Create a lookup table to map referralcode to username
        const referralLookup = {};
        dataArray.forEach(item => {
            referralLookup[item.referralcode] = item.username;
        });
        
        // Process each user and map referredby to the corresponding username
        dataArray.forEach(item => {
            const createdDateParts = item.createdon.split(" "); // Split date and time
            const createdDateParts2 = createdDateParts[1].split("-"); // Split date by hyphen
            const day = createdDateParts2[0];
            const month = createdDateParts2[1];
            const year = createdDateParts2[2];
            const createdDate2 = new Date(`${month}-${day}-${year} ${createdDateParts[0]}`); // Rearrange date format
            const createdDate3 = new Date(`${month}-${day}-${year}`); // Rearrange date format
            const dateString = createdDateParts[1].toString();
            
            userData.push({
                id: item.id,
                username: item.username,
                phone: item.phone,
                createdon: dateString,
                referralcode: item.referralcode,
                referredby: referralLookup[item.referredby] || null // Map referredby to username
            });
        });
        
        setDataNumberOfUsers(userData.length);
        setDataUsers(userData);
    });
};


  const formatter = new Intl.NumberFormat('en-KE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Read Round Results Function
  const ReadRoundResultsDataFun = () => {
    let ref = database.ref("round_results");
    ref.once("value", snapshot => {
      const data = snapshot.val();
      let totalRoundsPlayed = Object.keys(data).length;
      let totalStake = 0;
      let totalPayouts = 0;
      let totalNetProfit = 0;
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          totalStake += parseFloat(data[key].total_stake ? data[key].total_stake : 0);
          totalPayouts += parseFloat(data[key].game_over_total_payouts ? data[key].game_over_total_payouts : 0);
          totalNetProfit += parseFloat(data[key].net_profit ? data[key].net_profit : 0);

        }
      }

      setDataStakes(formatter.format(totalStake));
      setDataPayouts(formatter.format(totalPayouts));
      setDataProfit(formatter.format(totalNetProfit));

      /*
      setDataStakes(totalStake.toFixed(2));
      setDataPayouts(totalPayouts.toFixed(2));
      setDataProfit(totalNetProfit.toFixed(2));
      */

      const aggregatedData = aggregateDataByDay(data);
      const aggregatedDataWeek = aggregateDataByWeek(data);
      const aggregatedDataMonth = aggregateDataByMonth(data);
      setDataRoundResults(aggregatedData.reverse());
      setDataRoundResultsWeek(aggregatedDataWeek.reverse());
      setDataRoundResultsMonth(aggregatedDataMonth.reverse());
      //console.log(aggregatedData);
      //console.log(aggregatedDataWeek);
      //console.log(aggregatedDataMonth);

      const { labels, netProfits } = calculateNetProfit(data, 'daily'); // Change 'daily' to 'weekly' or 'monthly' for other intervals

      setChartData({
        labels: labels,
        datasets: [{
          label: "Net Profit",
          data: netProfits,
          backgroundColor: netProfits.map(profit => profit < 0 ? 'red' : 'green'), // Set colors based on net profit
        }]
      });
    })
  }

  const ReadCumulativeDataFun = () => {
    let ref = database.ref("round_results");
    const roundResultsData = [];
    ref.once("value", snapshot => {
      const data = snapshot.val();
      const dataArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
      dataArray.forEach(item => {
        roundResultsData.push({
          after_all_payouts: item.after_all_payouts,
          bust: item.bust,
          timestamp: item.timestamp
        });
      });

      let totalRoundsPlayed = Object.keys(data).length;
      let totalStake = 0;
      let totalPayouts = 0;
      let totalNetProfit = 0;
      let stakesLast7Days = 0;
      let payoutsLast7Days = 0;
      let profitLast7Days = 0;

      // Get the current date and the date 7 days ago
      const currentDate = new Date();
      const sevenDaysAgoDate = new Date();
      sevenDaysAgoDate.setDate(currentDate.getDate() - 7);

      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          totalStake += parseFloat(data[key].total_stake ? data[key].total_stake : 0);
          totalPayouts += parseFloat(data[key].game_over_total_payouts ? data[key].game_over_total_payouts : 0);
          totalNetProfit += parseFloat(data[key].net_profit ? data[key].net_profit : 0);

          // Check if the round result is within the last 7 days
          const roundDate = new Date(data[key].timestamp); // Directly convert ISO 8601 string to Date object
          if (roundDate >= sevenDaysAgoDate) {
            stakesLast7Days += parseFloat(data[key].total_stake ? data[key].total_stake : 0);
            payoutsLast7Days += parseFloat(data[key].game_over_total_payouts ? data[key].game_over_total_payouts : 0);
            profitLast7Days += parseFloat(data[key].net_profit ? data[key].net_profit : 0);
          }
        }
      }

      const previousTotalStake = totalStake - stakesLast7Days;
      const stakeGrowthPercentage = previousTotalStake > 0
      ? ((stakesLast7Days / previousTotalStake) * 100).toFixed(2)
      : stakesLast7Days > 0
        ? 100
        : 0;

      const previousTotalPayouts = totalPayouts - payoutsLast7Days;
      const payoutGrowthPercentage = previousTotalPayouts > 0
      ? ((payoutsLast7Days / previousTotalPayouts) * 100).toFixed(2)
      : payoutsLast7Days > 0
        ? 100
        : 0;

      const previousTotalProfit = totalNetProfit - profitLast7Days;
      const profitGrowthPercentage = previousTotalProfit > 0
      ? ((profitLast7Days / previousTotalProfit) * 100).toFixed(2)
      : profitLast7Days > 0
        ? 100
        : 0;

      setDataStakes(formatter.format(totalStake));
      setDataPayouts(formatter.format(totalPayouts));
      setDataProfit(formatter.format(totalNetProfit));

      const aggregatedData = aggregateDataByDay(data);
      const aggregatedDataWeek = aggregateDataByWeek(data);
      const aggregatedDataMonth = aggregateDataByMonth(data);
      setDataRoundResults(aggregatedData.reverse());
      setDataRoundResultsWeek(aggregatedDataWeek.reverse());
      setDataRoundResultsMonth(aggregatedDataMonth.reverse());

      const { labels, netProfits, cumulativeStakes, cumulativePayouts, cumulativeProfits } = calculateCumulativeData(data);

      setChartData({
        labels: labels,
        datasets: [{
          label: "Net Profit",
          data: netProfits,
          backgroundColor: netProfits.map(profit => profit < 0 ? 'red' : 'green'), // Set colors based on net profit
        }]
      });
      setChartDataStakes({
        labels: labels,
        datasets: [{
          label: "Cumulative Stakes",
          data: cumulativeStakes,
          backgroundColor: 'blue', // Color for cumulative stakes
        }]
      });

      setChartDataPayouts({
        labels: labels,
        datasets: [{
          label: "Cumulative Payouts",
          data: cumulativePayouts,
          backgroundColor: 'purple', // Color for cumulative payouts
        }]
      });

      setChartDataProfit({
        labels: labels,
        datasets: [{
          label: "Cumulative Profit",
          data: cumulativeProfits,
          backgroundColor: 'orange', // Color for cumulative profit
        }]
      });

      // Set growth percentages
      /*
      console.log(`${stakeGrowthPercentage}% stake growth last 7 days`);
      console.log(`${payoutGrowthPercentage}% payout growth last 7 days`);
      console.log(`${profitGrowthPercentage}% profit growth last 7 days`);
      */
      
      setStakeGrowthPercentage(`${stakeGrowthPercentage}%`);
      setPayoutGrowthPercentage(`${payoutGrowthPercentage}%`);
      setProfitGrowthPercentage(`${profitGrowthPercentage}%`);
    });
  };

  function calculateCumulativeData(data) {
    const labels = [];
    const netProfits = [];
    const cumulativeStakes = [];
    const cumulativePayouts = [];
    const cumulativeProfits = [];

    const aggregatedData = aggregateDataByDay(data);

    let cumulativeStake = 0;
    let cumulativePayout = 0;
    let cumulativeProfit = 0;

    for (const entry of aggregatedData) {
      labels.push(entry.date);
      netProfits.push(entry.totalNetProfit);

      cumulativeStake += parseFloat(entry.totalStake);
      cumulativePayout += parseFloat(entry.totalPayouts);
      cumulativeProfit += parseFloat(entry.totalNetProfit);

      cumulativeStakes.push(cumulativeStake.toFixed(2));
      cumulativePayouts.push(cumulativePayout.toFixed(2));
      cumulativeProfits.push(cumulativeProfit.toFixed(2));
    }
    return { labels, netProfits, cumulativeStakes, cumulativePayouts, cumulativeProfits };
  }

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: "Net Profit",
      data: [],
    }]
  });

  const [chartDataStakes, setChartDataStakes] = useState({
    labels: [],
    datasets: [{
      label: "Cumulative Stakes",
      data: [],
    }]
  });

  const [chartDataPayouts, setChartDataPayouts] = useState({
    labels: [],
    datasets: [{
      label: "Cumulative Payouts",
      data: [],
    }]
  });

  const [chartDataProfit, setChartDataProfit] = useState({
    labels: [],
    datasets: [{
      label: "Cumulative Profit",
      data: [],
    }]
  });

  const [chartDataUsers, setChartDataUsers] = useState({
    labels: [],
    datasets: [{
      label: "Cumulative Users",
      data: [],
    }]
  });

  function calculateCumulativeUsers(userData) {
  const labels = [];
  const dailyUserCounts = {};
  const cumulativeUsers = [];

  // Count users per day
  userData.forEach(user => {
    if (dailyUserCounts[user.createdon]) {
      dailyUserCounts[user.createdon]++;
    } else {
      dailyUserCounts[user.createdon] = 1;
    }
  });

  let cumulativeCount = 0;

  // Generate labels and cumulative user data
  Object.keys(dailyUserCounts).sort().forEach(date => {
    labels.push(date);
    cumulativeCount += dailyUserCounts[date];
    cumulativeUsers.push(cumulativeCount);
  });

  return { labels, cumulativeUsers };
}


  /*
  const [chartData, setChartData] = useState({
    labels: tableData.map((data) => data.bust),
    datasets: [{
      label: "Net Profit",
      data: tableData.map((data) => data.net_profit),
    }]
  }); */

  // Function to update chart data based on selected interval
  function updateChartData(interval) {
    let ref = database.ref("round_results");
    ref.once("value", snapshot => {
      const data = snapshot.val();
      const { labels, netProfits } = calculateNetProfit(data, interval);

      const datasets = [{
        label: "Net Profit",
        data: netProfits,
        backgroundColor: netProfits.map(profit => profit < 0 ? 'red' : 'green'), // Set colors based on net profit
      }];

      setChartData({
        labels: labels,
        datasets: datasets,
      });
    });
  }

  // Function to aggregate data per day
  function aggregateDataByDay(data) {
    const aggregatedData = [];

    for (const entryKey in data) {
      const entry = data[entryKey];
      const entryDate = new Date(entry.timestamp).toLocaleDateString();

      // Check if entryDate already exists in aggregatedData
      const existingEntry = aggregatedData.find(item => item.date === entryDate);

      if (!existingEntry) {
        // If the entryDate doesn't exist, add a new entry
        aggregatedData.push({
          date: entryDate,
          totalNetProfit: parseFloat(entry.net_profit).toFixed(2),
          totalStake: parseFloat(entry.total_stake).toFixed(2),
          totalGrossProfit: parseFloat(entry.gross_profit).toFixed(2),
          totalPayouts: parseFloat(entry.game_over_total_payouts).toFixed(2),
          roundsPlayed: 1 // Initialize rounds played to 1 for the current entry
        });
      } else {
        // If the entryDate already exists, update the existing entry
        existingEntry.totalNetProfit = (parseFloat(existingEntry.totalNetProfit) + parseFloat(entry.net_profit)).toFixed(2);
        existingEntry.totalStake = (parseFloat(existingEntry.totalStake) + parseFloat(entry.total_stake)).toFixed(2);
        existingEntry.totalGrossProfit = (parseFloat(existingEntry.totalGrossProfit) + parseFloat(entry.gross_profit)).toFixed(2);
        existingEntry.totalPayouts = (parseFloat(existingEntry.totalPayouts) + parseFloat(entry.game_over_total_payouts)).toFixed(2);
        existingEntry.roundsPlayed++; // Increment rounds played for the current entry
      }
    }
    return aggregatedData;
  }

  // Function to aggregate data per week
  function aggregateDataByWeek(data) {
    const aggregatedData = [];

    for (const entryKey in data) {
      const entry = data[entryKey];
      const entryDate = new Date(entry.timestamp);
      const weekStartDate = getWeekStartDate(entryDate).toLocaleDateString();
      const weekEndDate = getWeekEndDate(entryDate).toLocaleDateString();
      const weekLabel = `${weekStartDate} - ${weekEndDate}`;

      // Check if entryDate already exists in aggregatedData
      const existingEntryIndex = aggregatedData.findIndex(item => item.date === weekLabel);

      if (existingEntryIndex === -1) {
        // If the entryDate doesn't exist, add a new entry
        aggregatedData.push({
          date: weekLabel,
          startDate: weekStartDate,
          endDate: weekEndDate,
          totalNetProfit: parseFloat(entry.net_profit).toFixed(2),
          totalStake: parseFloat(entry.total_stake).toFixed(2),
          totalGrossProfit: parseFloat(entry.gross_profit).toFixed(2),
          totalPayouts: parseFloat(entry.game_over_total_payouts).toFixed(2),
          roundsPlayed: 1 // Initialize rounds played to 1 for the current entry
        });
      } else {
        // If the entryDate already exists, update the existing entry
        aggregatedData[existingEntryIndex].totalNetProfit = (parseFloat(aggregatedData[existingEntryIndex].totalNetProfit) + parseFloat(entry.net_profit)).toFixed(2);
        aggregatedData[existingEntryIndex].totalStake = (parseFloat(aggregatedData[existingEntryIndex].totalStake) + parseFloat(entry.total_stake)).toFixed(2);
        aggregatedData[existingEntryIndex].totalGrossProfit = (parseFloat(aggregatedData[existingEntryIndex].totalGrossProfit) + parseFloat(entry.gross_profit)).toFixed(2);
        aggregatedData[existingEntryIndex].totalPayouts = (parseFloat(aggregatedData[existingEntryIndex].totalPayouts) + parseFloat(entry.game_over_total_payouts)).toFixed(2);
        aggregatedData[existingEntryIndex].roundsPlayed++; // Increment rounds played for the current entry
      }
    }

    return aggregatedData;
  }

  // Function to aggregate data per month
  function aggregateDataByMonth(data) {
    const aggregatedData = [];

    for (const entryKey in data) {
      const entry = data[entryKey];
      const entryDate = new Date(entry.timestamp);
      const month = entryDate.getMonth();
      const year = entryDate.getFullYear();
      const monthLabel = `${year}-${month}`;

      // Check if entryDate already exists in aggregatedData
      const existingEntryIndex = aggregatedData.findIndex(item => item.date === monthLabel);

      if (existingEntryIndex === -1) {
        // If the entryDate doesn't exist, add a new entry
        aggregatedData.push({
          date: monthLabel,
          year: year,
          month: month + 1, // Adding 1 to month to make it 1-indexed
          totalNetProfit: parseFloat(entry.net_profit).toFixed(2),
          totalStake: parseFloat(entry.total_stake).toFixed(2),
          totalGrossProfit: parseFloat(entry.gross_profit).toFixed(2),
          totalPayouts: parseFloat(entry.game_over_total_payouts).toFixed(2),
          roundsPlayed: 1 // Initialize rounds played to 1 for the current entry
        });
      } else {
        // If the entryDate already exists, update the existing entry
        aggregatedData[existingEntryIndex].totalNetProfit = (parseFloat(aggregatedData[existingEntryIndex].totalNetProfit) + parseFloat(entry.net_profit)).toFixed(2);
        aggregatedData[existingEntryIndex].totalStake = (parseFloat(aggregatedData[existingEntryIndex].totalStake) + parseFloat(entry.total_stake)).toFixed(2);
        aggregatedData[existingEntryIndex].totalGrossProfit = (parseFloat(aggregatedData[existingEntryIndex].totalGrossProfit) + parseFloat(entry.gross_profit)).toFixed(2);
        aggregatedData[existingEntryIndex].totalPayouts = (parseFloat(aggregatedData[existingEntryIndex].totalPayouts) + parseFloat(entry.game_over_total_payouts)).toFixed(2);
        aggregatedData[existingEntryIndex].roundsPlayed++; // Increment rounds played for the current entry
      }
    }
    return aggregatedData;
  }

  // Helper function to get the start date of the week containing the given date
  function getWeekStartDate(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  }

  // Helper function to get the end date of the week containing the given date
  function getWeekEndDate(date) {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? 0 : 7); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  }

  function checkAuthState() {
    // Using the auth object to access Firebase Auth directly (e.g., check if a user is logged in)
    auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User is logged in:', user);
      } else {
        console.log('No user is logged in');
      }
    });
  }

  function signUpUser() {
    // Using the signUp function to create a new user
    signUp('user@example.com', 'password123')
    .then(user => {
      console.log('User created:', user);
    })
    .catch(error => {
      console.error('Sign up error:', error);
    });
  }

  function signInUser() {
    // Using the signIn function to log in an existing user
    signIn('user@example.com', 'password123')
    .then(user => {
      console.log('User signed in:', user);
    })
    .catch(error => {
      console.error('Sign in error:', error);
    });
  }

  function signOutUser() {
    // Using the signOut function to log out the current user
    signOut()
    .then(() => {
      console.log('User signed out');
    })
    .catch(error => {
      console.error('Sign out error:', error);
    });
  }

  useEffect(() => {
    checkAuthState();
    ReadCumulativeDataFun();
    ReadRoundResultsDataFun();
    ReadUsersDataFun();
    ReadUsersAffiliatesDataFun();
    ReadRecentTransactionsDataFun();
  }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu on link click
  };

  const handleLogout = () => {
    // Handle logout functionality here
    signOutUser();
    //alert("Logging out...");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Router>
        <div className="app">

          <header className="header top sticky">
            <button className="menu-button" onClick={toggleMenu}>
              ☰ Fatcat
            </button>
            <nav className={`navigation ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
              <ul>
                <li>
                  <Link to="/" onClick={handleLinkClick}>
                    Overview
                  </Link>
                </li>
                <li>
                  <Link to="/players" onClick={handleLinkClick}>
                    Players
                  </Link>
                </li>
                <li>
                  <Link to="/cashflow" onClick={handleLinkClick}>
                    Cashflow
                  </Link>
                </li>
                <li>
                  <Link to="/affiliates" onClick={handleLinkClick}>
                    Affiliates
                  </Link>
                </li>
                <li>
                  <Link to="/settings" onClick={handleLinkClick}>
                    Settings
                  </Link>
                </li>
                <li>
                  <div className="logout" onClick={handleLogout}>
                    <span>Signout</span>
                    <span><FontAwesomeIcon icon={faSignOut} /></span>
                  </div>
                </li>
              </ul>
            </nav>
            <span className="logout-button" onClick={handleLogout}>
              <a href='#'><FontAwesomeIcon icon={faSignOut} /></a>
            </span>
          </header>

          <main className="ckhks90">
            <Dash
              dataNumberOfUsers={dataNumberOfUsers}
              tableDataUsers={dataUsers}
              tableData={dataRoundResults}
              tableDataWeek={dataRoundResultsWeek}
              tableDataMonth={dataRoundResultsMonth}
              chartData={chartData}
              ReadUsersDataFun={ReadUsersDataFun}
              ReadRoundResultsDataFun={ReadRoundResultsDataFun}
              updateChartData={updateChartData}
              dataStakes={dataStakes}
              dataPayouts={dataPayouts}
              dataProfit={dataProfit}
              dataAffiliates={dataAffiliates}
              totalAffiliates={totalAffiliates}
              activeAffiliates={activeAffiliates}
              paidCommissions={paidCommissions}
              unpaidCommissions={unpaidCommissions}
              chartDataStakes={chartDataStakes}
              chartDataPayouts={chartDataPayouts}
              chartDataProfit={chartDataProfit}
              chartDataUsers={chartDataUsers}
              dataRecentTranscations={dataRecentTranscations}
              userGrowthPercentage={userGrowthPercentage}
              stakeGrowthPercentage={stakeGrowthPercentage}
              payoutGrowthPercentage={payoutGrowthPercentage}
              profitGrowthPercentage={profitGrowthPercentage}
            />
            <div className='footer'>
              <div>
                Fatcat Admin Dashboard
              </div>
            </div>
          </main>

        </div>
      </Router>
    </>
  );
};

export default App;
