import firebase from 'firebase/app'; // Import only the base Firebase module
import 'firebase/auth'; // Import the Firebase Authentication module
import 'firebase/database'; // Import the Realtime Database module

const firebaseConfig = {
  apiKey: "AIzaSyDiWdMOiNN8eCQohrxK_KmVjf-0zi2-_gg",
  authDomain: "fatcat-sol-b0bbf.firebaseapp.com",
  databaseURL: "https://fatcat-sol-b0bbf-default-rtdb.firebaseio.com",
  projectId: "fatcat-sol-b0bbf",
  storageBucket: "fatcat-sol-b0bbf.appspot.com",
  messagingSenderId: "280273241381",
  appId: "1:280273241381:web:ed941c00c6c7824063c6cc",
  measurementId: "G-SB74F49DQS"
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
const auth = firebase.auth();

const signUp = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password)
    .then(userCredential => {
      const user = userCredential.user;
      console.log("User signed up:", user);
      return user;
    })
    .catch(error => {
      console.error("Error signing up:", error);
      throw error;
    });
};

const signIn = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password)
    .then(userCredential => {
      const user = userCredential.user;
      console.log("User signed in:", user);
      return user;
    })
    .catch(error => {
      console.error("Error signing in:", error);
      throw error;
    });
};

const signOut = () => {
  return auth.signOut()
    .then(() => {
      console.log("User signed out successfully");
    })
    .catch(error => {
      console.error("Error signing out:", error);
      throw error;
    });
};

export { database, auth, signUp, signIn, signOut };
