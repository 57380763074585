import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Overview from "./Overview";
import Players from "./Players";
import Cashflow from "./Cashflow";
import Affiliates from "./Affiliates";
import Settings from "./Settings";


function Dash({  dataNumberOfUsers, tableDataUsers, tableData, tableDataWeek, tableDataMonth, chartData, ReadUsersDataFun, ReadRoundResultsDataFun, updateChartData, dataStakes, dataPayouts, dataProfit, dataAffiliates, totalAffiliates, activeAffiliates, paidCommissions, unpaidCommissions, chartDataStakes, chartDataPayouts, chartDataProfit, chartDataUsers, dataRecentTranscations, userGrowthPercentage, stakeGrowthPercentage, payoutGrowthPercentage, profitGrowthPercentage }) {

  return (
    <Routes>
      <Route path="/" element={
        <Overview
          dataNumberOfUsers={dataNumberOfUsers}
          dataStakes={dataStakes}
          dataPayouts={dataPayouts}
          dataProfit={dataProfit}
          chartDataStakes={chartDataStakes}
          chartDataPayouts={chartDataPayouts}
          chartDataProfit={chartDataProfit}
          chartDataUsers={chartDataUsers}
          dataRecentTranscations={dataRecentTranscations}
          userGrowthPercentage={userGrowthPercentage}
          stakeGrowthPercentage={stakeGrowthPercentage}
          payoutGrowthPercentage={payoutGrowthPercentage}
          profitGrowthPercentage={profitGrowthPercentage}
        />} />
      <Route path="/players" element={
        <Players
          dataNumberOfUsers={dataNumberOfUsers}
          data={tableDataUsers}
          ReadUsersDataFun={ReadUsersDataFun}
          userGrowthPercentage={userGrowthPercentage}
        />} />
      <Route path="/cashflow" element={
        <Cashflow
          ReadRoundResultsDataFun={ReadRoundResultsDataFun}
          chartData={chartData}
          tableData={tableData}
          tableDataWeek={tableDataWeek}
          tableDataMonth={tableDataMonth}
          updateChartData={updateChartData}
        />} />
      <Route path="/affiliates" element={
        <Affiliates
          dataAffiliates={dataAffiliates}
          totalAffiliates={totalAffiliates}
          activeAffiliates={activeAffiliates}
          paidCommissions={paidCommissions}
          unpaidCommissions={unpaidCommissions}
        />} />
      <Route path="/settings" element={
        <Settings
        />} />
    </Routes>
  )

}

export default Dash;