// LineChartComponent.js
import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const LineChartComponent = ({ data }) => {
  const chartRef = useRef(null); // Create a ref for the chart

  // Utility function to get colors for positive and negative values
  const getColor = (value) => value >= 0 ? '#4CAF50' : '#F44336'; // Green for positive, Red for negative

  // Utility function to format numbers
  const formatNumber = (value) => {
    if (Math.abs(value) >= 1e6) {
      return (value / 1e6).toFixed(0) + 'M'; // Millions
    } else if (Math.abs(value) >= 1e3) {
      return (value / 1e3).toFixed(0) + 'K'; // Thousands
    } else {
      return value.toFixed(0); // No decimal places
    }
  };

  // Modify the data to include borderWidth, tension, colors, and border radius based on value
  const modifiedData = {
    labels: data.labels,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      borderWidth: 2, // Thicker line for better visibility
      tension: 0.8,   // Smooth curves
      borderColor: dataset.data.map(value => getColor(value)),
      pointBackgroundColor: dataset.data.map(value => getColor(value)),
      pointBorderColor: '#ffffff', // White border around the points
      pointBorderWidth: 2, // Width of the border around the points
      borderRadius: 5, // Apply Material Design border radius
    }))
  };

  const options = {
    responsive: true, // Make chart responsive
    maintainAspectRatio: false, // Allow the chart to fill its container
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Lighter grid color
          borderColor: 'rgba(0, 0, 0, 0.1)', // Lighter border color
          borderWidth: 1,
        },
        ticks: {
          display: true, // Show ticks
          callback: function(value, index, values) {
            return ''; // Hide tick labels
          },
          crossAlign: 'center',
          font: {
            size: 10, // Smaller font size
          }
        },
        afterBuildTicks: function(scale) {
          // Replace tick marks with dots
          const ticks = scale.ticks;
          return ticks.map((tick) => ({
            ...tick,
            label: '•', // Dot character
            color: 'rgba(0, 0, 0, 0.5)' // Dot color
          }));
        }
      },
      y: {
        min: Math.min(20000, Math.min(...data.datasets.flatMap(dataset => dataset.data)) - 20000),
        max: Math.max(20000, Math.max(...data.datasets.flatMap(dataset => dataset.data)) + 20000),
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Lighter grid color
          borderColor: 'rgba(0, 0, 0, 0.1)', // Lighter border color
          borderWidth: 1,
        },
        ticks: {
          callback: function(value) {
            return formatNumber(value); // Format y-axis labels
          },
          font: {
            size: 10, // Smaller font size
          },
          padding: 0,
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
        mode: 'nearest', // Show tooltip for the nearest data point
        intersect: false, // Show tooltip even if not intersecting
        callbacks: {
          label: function(context) {
            const value = context.raw;
            return `Value: ${formatNumber(value)}`; // Detailed tooltip content
          },
        },
        backgroundColor: '#333',
        titleColor: '#fff',
        bodyColor: '#fff',
        borderColor: '#ddd',
        borderWidth: 1,
        padding: 10,
        caretSize: 6,
        displayColors: false,
      },
    },
    onClick: (event) => {
      const chart = chartRef.current?.chartInstance;
      if (!chart) return;

      const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      if (points.length) {
        // Trigger tooltip display on click
        chart.tooltip.setActiveElements(points, { x: event.native.clientX, y: event.native.clientY });
        chart.update();
      }
    }
  };

  useEffect(() => {
    const chart = chartRef.current?.chartInstance;
    if (chart) {
      chart.resize(); // Ensure chart resizes with its container
    }
  }, [data]);

  return (
    <div className="chart-container">
      <h3>Net Profits</h3>
      <Line data={modifiedData} options={options} ref={chartRef} />
    </div>
  );
};

export default LineChartComponent;
