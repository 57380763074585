import React, { useState } from 'react';
import ChartComponent from './components/ChartComponent';
import LineChartComponent from './components/LineChartComponent';
import IntervalSelect from './components/IntervalSelect';
import TableComponent from './components/TableComponent';
import WeekTableComponent from './components/WeekTableComponent';
import MonthTableComponent from './components/MonthTableComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarDay, faCalendarWeek, faRefresh } from '@fortawesome/free-solid-svg-icons';

function Cashflow({ ReadRoundResultsDataFun, chartData, tableData, tableDataWeek, tableDataMonth, updateChartData }) {
  const [selectedInterval, setSelectedInterval] = useState('daily');

  const handleIntervalChange = interval => {
    setSelectedInterval(interval);
  };

  return (
    <>
      <div id='cashflowSection' className='section'>

        <div className='header section'>
          <div className='grid-2-col nav'>
            <h2>Cashflow</h2>
            <a className="btn refresh-button" onClick={ReadRoundResultsDataFun}>
              <FontAwesomeIcon icon={faRefresh} />
            </a>
          </div>
        </div>

        <div className='body section'>

          <div className='grid-2-rows section'>
            <div className='container nav'>
              <span className="update-btn" onClick={() => updateChartData('daily')}><FontAwesomeIcon icon={faCalendarDay} />Daily</span>
              <span className="update-btn" onClick={() => updateChartData('weekly')}><FontAwesomeIcon icon={faCalendarWeek} />Weekly </span>
              <span className="update-btn" onClick={() => updateChartData('monthly')}><FontAwesomeIcon icon={faCalendar} />Monthly</span>
            </div>

            <div className='grid-2-col section'>
              <div className='graph section border'>
                <ChartComponent data={chartData} />
              </div>
              <div className='graph section border'>
                <LineChartComponent data={chartData} />
              </div>
            </div>
          </div>

          <div className='grid section border p16'>

            <div className='grid-2-rows'>

              <div className='grid-2-col nav'>
                <div className='left-content'>
                  <h3>Cashflow Over time</h3>
                </div>
                <div className='right-content'>
                  <IntervalSelect onChange={handleIntervalChange} />
                </div>
              </div>

              <div className='tables'>
                {selectedInterval === 'daily' &&
                  <TableComponent data={tableData} />
                }
                {selectedInterval === 'weekly' &&
                  <WeekTableComponent data={tableDataWeek} />
                }
                {selectedInterval === 'monthly' &&
                  <MonthTableComponent data={tableDataMonth} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cashflow;