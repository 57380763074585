import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faPeopleGroup, faPersonMilitaryToPerson, faSync, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

function Affiliates({dataAffiliates, totalAffiliates, activeAffiliates, paidCommissions, unpaidCommissions}) {
  return (
    <>
      <div id='affiliatesSection' className='section'>

        <div className='header section'>
          <div className='grid-2-col nav'>
            <h2>Affiliates</h2>
            <a className="btn refresh-button">
              <FontAwesomeIcon icon={faSync} />
            </a>
          </div>

        </div>
        <div className='summary section'>
          <div className='grid-4-col'>

            <div className='container tile shadow border'>
              <div><span className='round-bg red'><FontAwesomeIcon icon={faPeopleGroup} /></span> Affiliates</div>
              <div className='main-stat'><p>{totalAffiliates}</p></div>
            </div>

            <div className='container tile shadow border'>
              <div><span className='round-bg blue'><FontAwesomeIcon icon={faPersonMilitaryToPerson} /></span> Active</div>
              <div className='main-stat'><span>{activeAffiliates}</span></div>
            </div>

            <div className='container tile shadow border'>
              <div><span className='round-bg red'><FontAwesomeIcon icon={faMoneyBill} /></span> Paid Commisions</div>
              <div className='main-stat'><p>{paidCommissions}</p></div>
            </div>

            <div className='container tile shadow border'>
              <div><span className='round-bg blue'><FontAwesomeIcon icon={faMoneyBill} /></span> Unpaid Commisions</div>
              <div className='main-stat'><span>{unpaidCommissions}</span></div>
            </div>

          </div>
        </div>

        <div className='body section'>

          <div className='grid section border p16'>
            <div className='grid-2-col nav '>
              <div className='left-content'>
                <h4>Top 10 Affiliates</h4>
              </div>
              <div className='right-content'>
                <a className="refresh-button">
                  <FontAwesomeIcon icon={faSyncAlt} />
                </a>
              </div>
            </div>

            <div className='grid section'>
              <div className='container'>
                <div className="table-container shadow border">
                  <table>
                    <thead>
                      <tr>
                        <th className='username'>Name</th>
                        <th className='phone'>Invitees</th>
                        <th className='refcode'>Earnings</th>
                        <th className='refcode'>Paid</th>
                        <th className='refcode'>Due</th>
                        <th className='refcode'>Ref Code</th>
                        <th className='date'>Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                    {dataAffiliates.map((item, index) => (
                      <tr key={index}>
                        <td className='username'>{item.username}</td>
                        <td className='phone'>{item.invitees}</td>
                        <td className='refcode'>{item.earnings}</td>
                        <td className='refcode'>{item.paid}</td>
                        <td className='user-id'>{item.due}</td>
                        <td className='user-id'>{item.refCode}</td>
                        <td className='user-id'>{item.joined}</td>
                      </tr>
                    ))}

                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className='container tile gray'>Horr bar chart</div> */}
            </div>
          </div>

        </div>

      </div>
    </>
  );
}

export default Affiliates;