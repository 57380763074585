import React, { useEffect, useState } from 'react';
//import database from './firebase-config';
import { database, auth, signUp, signIn, signOut } from './firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faMoneyBill, faMoneyBillWave, faSyncAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import PayoutsChartComponent from './components/PayoutsChartComponent';
import ProfitChartComponent from './components/ProfitChartComponent';
import StakesChartComponent from './components/StakesChartComponent';
import DailyUsersLineChartComponent from './components/DailyUsersLineChartComponent';


function Overview({ dataNumberOfUsers, dataStakes, dataPayouts, dataProfit, chartDataStakes, chartDataPayouts, chartDataProfit, chartDataUsers, dataRecentTranscations, userGrowthPercentage, stakeGrowthPercentage, payoutGrowthPercentage, profitGrowthPercentage }) {
  const [chats, setChats] = useState([]);
  const [chatMessage, setChatMessage] = useState('');

  const handleSendMessage = (e) => {
    e.preventDefault();
    // Create a new Date object
    const currentTime = new Date();
    // Get the current hour, minute, and second
    const currentDay = currentTime.getDate();
    const currentMonth = currentTime.getMonth();
    const currentYear = currentTime.getFullYear();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentSecond = currentTime.getSeconds();
    // Format the time as a string
    const formattedTime = `${currentDay}/${currentMonth}/${currentYear} - ${currentHour}:${currentMinute}:${currentSecond}`;
    const message = chatMessage;
    const chatData = {
      time: formattedTime,
      userMessage: message,
      username: 'Support',
      userID: 'jQILCjmTTSV4QY1jFlJOJJvjpn12'
    };
    const chatsRef = database.ref("chats").push();
    chatsRef.set(chatData)
      .then(() => {
        setChatMessage("");
        ReadChatsDataFun();
      })
      .catch(error => {
        console.error("Error sending message:" + error);
      });
  }

  // Read Chats Function
  /*
  const ReadChatsDataFun = () => {
    const chatsRef = database.ref("chats");
    chatsRef.once("value")
      .then(snapshot => {
        const chatsData = snapshot.val() || {}; // Default to an empty object if chatsData doesn't exist yet
        const chatsArray = Object.keys(chatsData).map(chatId => ({
          id: chatId,
          ...chatsData[chatId]
        }));
        setChats(chatsArray);
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  }
  */

  // Read Chats Function
  /*
  const ReadChatsDataFun = () => {
    const chatsRef = database.ref("chats");
    chatsRef.once("value")
    .then(snapshot => {
      const chatsData = snapshot.val() || {}; // Default to an empty object if chatsData doesn't exist yet
      const chatsArray = Object.keys(chatsData)
        .map(chatId => ({
          id: chatId,
          ...chatsData[chatId]
        }))
        .filter(chat => chat.username !== "FatCat"); // Filter out messages from 'FatCat'
      setChats(chatsArray);
    })
    .catch(error => {
      console.error("Error: " + error);
    });
  };
  */

  // Read Chats Function
  const ReadChatsDataFun = () => {
    const chatsRef = database.ref("chats");
    chatsRef.once("value")
      .then(snapshot => {
        const chatsData = snapshot.val() || {}; // Default to an empty object if chatsData doesn't exist yet
        const chatsArray = Object.keys(chatsData)
          .map(chatId => ({
            id: chatId,
            ...chatsData[chatId]
          }))
          .filter(chat =>
            chat.userMessage?.includes("@Support") ||
            chat.userMessage?.includes("@support") ||
            chat.username === "Support"
          ); // Filter to include specific messages or username
        setChats(chatsArray);
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  };


  useEffect(() => {
    ReadChatsDataFun();
  }, []);

  return (
    <>
      <div id='overviewSection' className='section'>

        <div className='header section'>
          <div className='grid-2-col nav'>
            <h1>Overview</h1>
            <a className="btn refresh-button">
              <FontAwesomeIcon icon={faSyncAlt} />
            </a>
          </div>
        </div>

        <div className='summary section'>
          <div className='grid-4-col'>

            <div className='container tile shadow silver'>
              <div><span className='round-bg red'><FontAwesomeIcon icon={faUser} /></span> Players</div>
              <div className='main-stat'><p>{dataNumberOfUsers}</p></div>
              <div><span className='green-txt'>{userGrowthPercentage} </span><span className='gray-txt light'>last 7days</span></div>
            </div>

            <div className='container tile shadow silver'>
              <div><span className='round-bg yellow'><FontAwesomeIcon icon={faDollar} /></span> Stakes</div>
              <div className='main-stat'><p>{dataStakes}</p></div>
              <div><span className='green-txt'>{stakeGrowthPercentage} </span><span className='gray-txt light'>last 7days</span></div>
            </div>

            <div className='container tile shadow silver'>
              <div><span className='round-bg blue'><FontAwesomeIcon icon={faMoneyBill} /></span> Payouts</div>
              <div className='main-stat'><p>{dataPayouts}</p></div>
              <div><span className='green-txt'>{payoutGrowthPercentage} </span><span className='gray-txt light'>last 7days</span></div>
            </div>

            <div className='container tile shadow silver'>
              <div><span className='round-bg blue'><FontAwesomeIcon icon={faMoneyBillWave} /></span>Profit</div>
              <div className='main-stat'><p>{dataProfit}</p></div>
              <div><span className='green-txt'>{profitGrowthPercentage} </span><span className='gray-txt light'>last 7days</span></div>
            </div>

          </div>
        </div>

        <div className='body section'>

          {/* User growth charts */}
          <div className='grid section border'>

            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h2>User growth overtime</h2>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faSyncAlt} /></a>
              </div>
            </div>

            <div className='grid-2-col section'>
              <div className='container'>
                <div className='graph section'>
                  <DailyUsersLineChartComponent data={chartDataUsers} />
                </div>
              </div>
              <div className='container border gray'>User map</div>
            </div>

          </div>

          {/* Revenue charts */}
          <div className='grid section border'>
            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h2>Revenue trend overtime</h2>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faSyncAlt} /></a>
              </div>
            </div>
            <div className='grid-3-col section'>
              <div className='container'>
                <div className='graph section'>
                  <StakesChartComponent data={chartDataStakes} />
                </div>
              </div>
              <div className='container'>
                <div className='graph section'>
                  <PayoutsChartComponent data={chartDataPayouts} />
                </div>
              </div>

              <div className='container'>
                <div className='graph section'>
                  <ProfitChartComponent data={chartDataProfit} />
                </div>
              </div>

            </div>
          </div>

          {/* Support functions */}
          <div className='grid section border p16'>
            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h2>Support Inbox</h2>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>' onClick={ReadChatsDataFun}>
                  <FontAwesomeIcon icon={faSyncAlt} /></a>
              </div>
            </div>

            {/* Chat box & transactions*/}
            <div className='grid-2-col section'>
              <div className='container shadow border'>
                <h2>Recent Messages</h2>
                <div className='container'>
                  <div className='chat'>
                    <div className='messages'>
                      {chats && chats.length > 0 ? (
                        chats.map((chat, index) => (
                          <div key={index} className='message'>
                            <span className='message-text strong'>{chat.username}</span>
                            <span className='message-text'>{chat.userMessage}</span>
                            <span className='message-text time right-align'>{chat.time}</span>
                          </div>
                        ))
                      ) : (
                        <div className="message">
                          <span className='message-text'>No Chat Messages</span>
                        </div>
                      )}
                    </div>
                    <div className='message-input'>
                      <input
                        id="chatMessage"
                        className='input'
                        name='chatMessage'
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        placeholder='Message...'
                        required
                      />
                      <button onClick={handleSendMessage}>Send</button>
                      {/* TODO: Error message  */}
                    </div>
                  </div>
                </div>
              </div>

              <div className='container shadow border'>
                <h2>Recent Transactions</h2>
                <div className="table-container">
                  <div className='container'>
                    <table>
                      <thead>
                        <tr>
                          <th>transactionDate</th>
                          <th>username</th>
                          <th>amount</th>
                          <th>action</th>
                          <th>phoneNumber</th>
                          <th>resultCode</th>
                          <th>resultDesc</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataRecentTranscations.map((item, index) => (
                          <tr key={index}>
                            <td>{item.transactionDate}</td>
                            <td>{item.username}</td>
                            <td>{item.amount}</td>
                            <td>{item.action}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{item.resultCode}</td>
                            <td>{item.resultDesc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* Other Updates */}
          <div className='grid-2-col section'>
            <div className='container tile border'>Latest members</div>
            <div className='container tile border'>Browser usage</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;

