import React, { useState } from 'react';
import Select from 'react-select';

const IntervalSelect = ({ onChange }) => {
  const options = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    onChange(selectedOption.value);
  };

  return (
    <>
      <Select
        className="select-container" // Add this class for styling
        classNamePrefix="select" // Add this class prefix for styling
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    </>
  );
};

export default IntervalSelect;
