// Overview.js
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputer, faSyncAlt, faUser } from '@fortawesome/free-solid-svg-icons';

function Players({ dataNumberOfUsers, data, ReadUsersDataFun, userGrowthPercentage }) {
  return (
    <>
      <div id="playersSection" className="section">

        <div className='header section'>
          <div className='grid-2-col nav'>
            <h2>Players Stats</h2>
            <a className="btn refresh-button" onClick={ReadUsersDataFun}>
              <FontAwesomeIcon icon={faSyncAlt} />
            </a>
          </div>
        </div>

        <div className="summary section">
          <div className='grid-4-col container'>

            <div className='container tile shadow border'>
              <div><span className='round-bg red'><FontAwesomeIcon icon={faUser} /></span> Players</div>
              <div className='main-stat'><p>{dataNumberOfUsers}</p></div>
              <div><span className='green-txt'>{userGrowthPercentage} </span><span className='gray-txt light'>last 7days</span></div>
            </div>

          </div>
        </div>

        <div className='body section'>

          <div className="grid section border p16">
            <div className='grid-2-col nav '>
              <div className='left-content'>
                <h4>List of users</h4>
              </div>
              <div className='right-content'>
                <a className="btn refresh-button" onClick={ReadUsersDataFun}>
                  <FontAwesomeIcon icon={faSyncAlt} />
                </a>
              </div>
            </div>

            <div className='grid section'>
              <div className='container'>
                <div className="table-container shadow border">
                  <table>
                    <thead>
                      <tr>
                        <th className='date'>Created</th>
                        <th className='username'>Name</th>
                        <th className='phone'>Phone</th>
                        <th className='refcode'>Ref Code</th>
                        <th className='refcode'>Ref By</th>
                        {/* <th className='user-id'>ID</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className='date right-align'>{item.createdon.split(',')[0]}</td>
                          <td className='username'>{item.username}</td>
                          <td className='phone'>{item.phone}</td>
                          <td className='refcode'>{item.referralcode}</td>
                          <td className='refcode'>{item.referredby}</td>
                          {/* <td className='user-id'>{item.id}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div className='grid-3-col section border p16'>
            <div className='container tile gray'>Messages
              <br />
              <br />
              User1: Message
            </div>
            <div className='container tile gray'>
              Latest members
              <br />
              <br />
              Members in last 7days
            </div>
            <div className='container tile gray'>
              Browser Usage
              <br />
              <br />
              Mobile: 99%
              <br />
              Desktop: 1%
            </div>
          </div>

        </div>

      </div>

    </>
  );

}

export default Players;
