import React from 'react';

const TableComponent = ({ data }) => {
  let totalDaysPlayed = data.length;

  // Function to format numbers
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0 
    }).format(number);
  };

  // Function to format dates to dd/mm/yyyy
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <>
      <div className='grid-2-col nav'>
        <h3>Daily Report</h3>
        <p className='right-align p0 m4'>Days: {totalDaysPlayed} </p>
      </div>

      <div className="table-container shadow border">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Rounds</th>
              <th>Stakes</th>
              <th>Payouts</th>
              <th>Gross</th>
              <th>Net</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{formatDate(item.date)}</td>
                <td>{formatNumber(item.roundsPlayed)}</td>
                <td>{formatNumber(item.totalStake)}</td>
                <td>{formatNumber(item.totalPayouts)}</td>
                <td>{formatNumber(item.totalGrossProfit)}</td>
                <td>{formatNumber(item.totalNetProfit)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableComponent;
