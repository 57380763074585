import React, { useEffect, useState } from 'react';
//import database from './firebase-config';
import { database, auth, signUp, signIn, signOut } from './firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faRefresh, faEdit, faDeleteLeft, faTrash, faSyncAlt, faPlug } from '@fortawesome/free-solid-svg-icons';

function Settings() {
  const [name, setName] = useState('');
  const [betAmount, setBetAmount] = useState(10);
  const [cashoutAt, setCashoutAt] = useState(1.01);
  const [status, setStatus] = useState('active');
  const [playingAs, setPlayingAs] = useState('bot');
  const [bots, setBots] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [botID, setBotID] = useState('');
  const [cashoutType, setCashoutType] = useState('fixed');
  const [returnToPlayer, setReturnToPlayer] = useState('');
  const [returnToCompany, setReturnToCompany] = useState('');
  const [companyAddon, setCompanyAddon] = useState('');

  const handleReturnToPlayerChange = (e) => {
    const newReturnToPlayer = e.target.value;
    setReturnToPlayer(newReturnToPlayer);
    setReturnToCompany(100 - newReturnToPlayer);
  };

  function getCurrentRTPValue() {
    let ref = database.ref("rtp");
    ref.once("value", snapshot => {
      const data = snapshot.val();
      setReturnToPlayer(data[0].rtp * 100);
    });
  }

  function getCurrentRTCValue() {
    let ref = database.ref("rtp");
    ref.once("value", snapshot => {
      const data = snapshot.val();
      setReturnToCompany(data[0].rtc * 100);
    });
  }

  function setCurrentRTPValue(rtp, rtc, companyAddon) {
    let rtpNew = rtp / 100;
    let rtcNew = rtc / 100;
    const companyAddonNew = companyAddon / 100;
    let fixedRTP = rtpNew.toFixed(2);
    let fixedRTC = rtcNew.toFixed(2);
    let fixedCompanyAddon = companyAddonNew.toFixed(2);
    var obj = {
      id: 1,
      rtc: fixedRTC,
      rtp: fixedRTP,
      company_addon: fixedCompanyAddon
    };
    database.ref("rtp").child("0").set(obj, function (error) {
      if (error) {
        // The write failed...
        console.log("Failed with error: " + error);
      } else {
        // The write was successful...
        //console.log("success")
        getCurrentRTCValue();
        getCurrentRTPValue();
        getCurrentCompanyAddonValue();
      }
    });
  }

  function getCurrentCompanyAddonValue() {
    let ref = database.ref("rtp");
    ref.once("value", snapshot => {
      const data = snapshot.val();
      setCompanyAddon(data[0].company_addon * 100);
    });
  }

  const handleSubmitRTCADDONSRTP = (e) => {
    e.preventDefault();

    // Display a confirmation message
    const isConfirmed = window.confirm('Are you sure you want to submit?');

    // If user confirms, proceed with form submission
    if (isConfirmed) {
      // Check if inputs are within the range
      const isValidInput = (value) => {
        const parsedValue = parseInt(value);
        return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 100;
      };

      if (isValidInput(returnToPlayer) && isValidInput(companyAddon)) {
        const parsedValueRTP = parseInt(returnToPlayer);
        const parsedValueCompanyAddon = parseInt(companyAddon);
        let rtc = 100 - parsedValueRTP;
        setCurrentRTPValue(parsedValueRTP, rtc, parsedValueCompanyAddon);
      } else {
        alert('Please enter valid numbers between 1 and 100.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a new bot object
    const newBot = {
      name,
      betAmount,
      cashoutAt,
      status,
      playingAs,
      cashoutType
    };

    // Check if editIndex is not null, meaning we're updating an existing bot
    if (editIndex !== null) {
      // Update the bot at editIndex
      bots[editIndex] = newBot;
      console.log(newBot);
      updateBotInDatabase(); // Call the function to update the bot's data in the database
      // Reset editIndex to null
      setEditIndex(null);
    } else {
      // Save the new bot to the database
      const botsRef = database.ref("bots");
      const botData = {
        name: name,
        betAmount: betAmount,
        cashoutAt: cashoutAt,
        status: status,
        playingAs: playingAs,
        cashoutType: cashoutType
      };
      // Push the new bot data to generate a unique ID
      botsRef.push(botData)
        .then((snapshot) => {
          // Retrieve the unique ID generated by Firebase
          const botId = snapshot.key;
          // Add the unique ID to the botData object
          const botDataWithId = { ...botData, id: botId };
          // Update the bot data in the database with the generated unique ID
          botsRef.child(botId).set(botDataWithId)
            .then(() => {
              ReadBotsDataFun();
              // Clear the form fields
              setName('');
              setBetAmount(10);
              setCashoutAt(1.01);
              setStatus('active');
              setPlayingAs('bot');
              setCashoutType('fixed');
            })
            .catch(error => {
              console.error("Error: " + error);
            });
        })
        .catch(error => {
          console.error("Error: " + error);
        });
    }
  };

  const handleEdit = (index, botId) => {
    // Set editIndex state to the index of the bot being edited
    setEditIndex(index);
    setBotID(botId);
    // Set form fields with data of the bot being edited
    const botToEdit = bots[index];
    setName(botToEdit.name);
    setBetAmount(botToEdit.betAmount);
    setCashoutAt(botToEdit.cashoutAt);
    setStatus(botToEdit.status);
    setPlayingAs(botToEdit.playingAs);
    setCashoutType(botToEdit.cashoutType);
  };

  // Function to update bot's data in the database
  const updateBotInDatabase = () => {
    console.log(botID);
    const botsRef = database.ref("bots");
    const botData = {
      name: name,
      betAmount: betAmount,
      cashoutAt: cashoutAt,
      status: status,
      playingAs: playingAs,
      cashoutType: cashoutType,
      id: botID
    };
    botsRef.child(botID).set(botData)
      .then(() => {
        // Refresh the bots data
        ReadBotsDataFun();
        setEditIndex(null);
        setBotID('');
        // Clear the form fields
        setName('');
        setBetAmount(10);
        setCashoutAt(1.01);
        setStatus('active');
        setPlayingAs('bot');
        setCashoutType('fixed');
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  };

  const handleDelete = (botId) => {
    console.log(botId);
    const botsRef = database.ref("bots");
    // Remove the bot from the database using its ID
    botsRef.child(botId).remove()
      .then(() => {
        ReadBotsDataFun();
        setEditIndex(null);
        setBotID('');
        // Clear the form fields
        setName('');
        setBetAmount(10);
        setCashoutAt(1.01);
        setStatus('active');
        setPlayingAs('bot');
        setCashoutType('fixed');
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  };

  // Read Bots Function
  const ReadBotsDataFun = () => {
    const botsRef = database.ref("bots");
    botsRef.once("value")
      .then(snapshot => {
        const botData = snapshot.val() || {}; // Default to an empty object if bot_data doesn't exist yet
        const botsArray = Object.keys(botData).map(botId => ({
          id: botId,
          ...botData[botId]
        }));

        // Now, botsArray contains an array of bot objects with their IDs
        setBots(botsArray);
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  }

  useEffect(() => {
    getCurrentRTCValue();
    getCurrentRTPValue();
    getCurrentCompanyAddonValue();
  }, []);

  useEffect(() => {
    ReadBotsDataFun();
  }, []);

  return (
    <>
      <div id='settingsSection' className='section'>

        <div className='header section'>
          <div className='grid-2-col nav'>
            <h2>Settings</h2>
            <a className="btn refresh-btn" >
              <FontAwesomeIcon icon={faRefresh} />
            </a>
          </div>
        </div>

        <div className='body section'>

          {/* Manage Database */}
          <div className='grid section border p16'>

            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h3>Manage Database Connection</h3>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faPlug} /> Connect
                </a>
              </div>
            </div>
          </div>

          {/* Manage RTP */}
          <div className='grid section border p16'>
            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h3>Manage RTP & Addon</h3>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faSyncAlt} /></a>
              </div>
            </div>
            <div className='form grid'>
              {/* RTP & RTC */}
              <div class="input-group">
                <div className='left-content'>
                  <p>Enter your RTP</p>
                  <p className='small-text'>RTC will autogenerate</p>
                </div>

                <div className='right-content'>
                  <div className='twinput'>
                    <input
                      id="returnToPlayer"
                      className='input'
                      name='returnToPlayer'
                      type="number"
                      value={returnToPlayer}
                      onChange={handleReturnToPlayerChange}
                      min="1"
                      max="100"
                      placeholder='My RTP is...'
                      autoComplete='off'
                      required
                    />
                    <input
                      id="returnToCompany"
                      className="button--submit italic"
                      name='returnToCompany'
                      type="text"
                      value={returnToCompany}
                      placeholder='Auto RTC...'
                      disabled="yes"
                    />
                  </div>

                </div>
              </div>

              {/* Company Addon */}
              <div className='input-group'>
                <div className='left-content'>
                  <p>Enter your Addon Percentage</p>
                </div>
                <div className='right-content'>
                  {/* <label htmlFor='companyAddon' className='text'>Addon:</label> */}
                  <input
                    id="companyAddon"
                    className='input'
                    name='companyAddon'
                    type="number"
                    value={companyAddon}
                    onChange={(e) => setCompanyAddon(e.target.value)}
                    min="1"
                    max="100"
                    required
                  />
                </div>
              </div>

              <div className='button-group'>
                <button className="submit-button" onClick={handleSubmitRTCADDONSRTP}>Update</button>
              </div>

            </div>

          </div>

          {/* Manage TRS */}
          <div className='grid section border p16'>

            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h3>Manage TRS</h3>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faAdd} /> Add
                </a>
              </div>
            </div>

          </div>

          {/* Manage Bots */}
          <div className='grid section border p16'>

            <div className='grid-2-col nav'>
              <div className='left-content'>
                <h3>Manage Bots</h3>
              </div>
              <div className='right-content'>
                <a className='btn add-btn>'>
                  <FontAwesomeIcon icon={faAdd} /> Add
                </a>
              </div>
            </div>

            <div className='grid section'>
              <div className='container'>
                <div className="table-container shadow border">
                  <table>
                    <thead>
                      <tr>
                        <th className='username'>Name</th>
                        <th>Bet Amount</th>
                        <th>Cashout</th>
                        <th>Status</th>
                        <th>Bot Type</th>
                        <th>Cashout Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bots && bots.length > 0 ? (
                        bots.map((bot, index) => (
                          <tr key={index}>
                            <td className='username'>{bot.name}</td>
                            <td>{bot.betAmount}</td>
                            <td>{bot.cashoutAt}</td>
                            <td>{bot.status}</td>
                            <td>{bot.playingAs}</td>
                            <td>{bot.cashoutType}</td>
                            <td>
                              <div className='grid-2-col'>
                                <button onClick={() => handleEdit(index, bot.id)}><FontAwesomeIcon icon={faEdit} /></button>
                                <button onClick={() => handleDelete(bot.id)}><FontAwesomeIcon icon={faTrash} /></button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7"> No bots available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='form-container shadow border'>
                <form className="form">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />

                  <label>Bet Amount:</label>
                  <input
                    type="number"
                    value={betAmount}
                    onChange={(e) => setBetAmount(parseInt(e.target.value))}
                    min={10}
                    max={3000}
                    required
                  />
                  <label>Cashout At:</label>
                  <input
                    type="number"
                    value={cashoutAt}
                    onChange={(e) => setCashoutAt(parseFloat(e.target.value))}
                    step="0.01"
                    min={1.01}
                    max={50.00}
                    required
                  />
                  <label>Status:</label>
                  <select
                    className="select-container" // Add this class for styling
                    classNamePrefix="select" // Add this class prefix for styling
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  >
                    <option value="active">Active</option>
                    <option value="not active">Not Active</option>
                  </select>

                  <label>Cashout Value Type:</label>
                  <select
                    className="select-container" // Add this class for styling
                    classNamePrefix="select" // Add this class prefix for styling
                    value={cashoutType}
                    onChange={(e) => setCashoutType(e.target.value)}
                    required
                  >
                    <option value="fixed">Fixed</option>
                    <option value="random">Random</option>
                  </select>

                  <label>Playing As:</label>
                  <select
                    className="select-container" // Add this class for styling
                    classNamePrefix="select" // Add this class prefix for styling
                    value={playingAs}
                    onChange={(e) => setPlayingAs(e.target.value)}
                    required
                  >
                    <option value="bot">Bot</option>
                    <option value="human">Human</option>
                  </select>

                  <button className="submit-button btn" onClick={handleSubmit}>
                    {editIndex !== null ? "Update Bot" : "Add Bot"}
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div >

    </>
  );
}

export default Settings;