// PayoutsChartComponent.js
import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const PayoutsChartComponent = ({ data }) => {
  const chartRef = useRef(null);

  const values = data.datasets.flatMap(dataset => dataset.data);
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const buffer = 20000;
  // const minYValue = Math.min(minValue - buffer, -20000); // Ensure y-axis doesn't go below -20,000
  const minYValue = 0; // Payouts cant be negative values honestly!
  const maxYValue = Math.max(maxValue + buffer, 20000); // Ensure y-axis doesn't go above 20,000

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Lighter grid color
          borderColor: 'rgba(0, 0, 0, 0.1)', // Lighter border color
          borderWidth: 1,
        },
        ticks: {
          display: true, // Show ticks
          callback: function (value, index, ticks) {
            return ''; // Show only dots on the x-axis
          },
          crossAlign: 'center',
          font: {
            size: 10, // Smaller font size
          }
        },
        afterBuildTicks: function (scale) {
          // Replace tick marks with dots
          const ticks = scale.ticks;
          return ticks.map((tick) => ({
            ...tick,
            label: '•', // Dot character
            color: 'rgba(0, 0, 0, 0.5)' // Dot color
          }));
        }

      },
      y: {
        beginAtZero: false,
        min: minYValue, // Set dynamic minimum value of the y-axis with a lower bound
        max: maxYValue, // Set dynamic maximum value of the y-axis with an upper bound
        ticks: {
          callback: function (value) {
            if (value >= 1000000 || value <= -1000000) {
              return (value / 1000000).toFixed(0) + 'M';
            } else if (value >= 1000 || value <= -1000) {
              return (value / 1000).toFixed(0) + 'K';
            } else {
              return value.toFixed(0);
            }
          },
          font: {
            size: 10, // Reduce the font size of the ticks
          },
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Lighter grid lines
          borderColor: 'rgba(0, 0, 0, 0.1)', // Border color for y-axis grid lines
          borderDash: [], // Solid lines
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false, // Remove tick marks
          lineWidth: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Enable tooltips
        mode: 'nearest', // Show tooltip for the nearest data point
        intersect: false, // Show tooltip even if not intersecting
        callbacks: {
          title: function (tooltipItems) {
            return `Details for ${tooltipItems[0].label}`;
          },
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `Value: ${value}`;
          },
        },
        backgroundColor: '#333',
        titleColor: '#fff',
        bodyColor: '#fff',
        borderColor: '#ddd',
        borderWidth: 1,
        padding: 10,
        caretSize: 6,
        displayColors: false,
      },
    },
    onClick: (event) => {
      const chart = chartRef.current?.chartInstance;
      if (!chart) return;

      const { offsetX, offsetY } = event.native;
      const canvasPosition = chart.canvas.getBoundingClientRect();
      const x = offsetX - canvasPosition.left;
      const y = offsetY - canvasPosition.top;

      const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      if (elements.length) {
        const { datasetIndex, index } = elements[0];
        chart.tooltip.setActiveElements([{ datasetIndex, index }], { x, y });
        chart.update();
      }
    },
  };

  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      barThickness: 5,
      backgroundColor: dataset.data.map((value) =>
        value > 0 ? '#4CAF50' : '#F44336'
      ),
      borderRadius: 8,
    })),
  };

  return (
    <div className="chart-container">
      <h3>Payouts</h3>
      <Bar data={updatedData} options={options} ref={chartRef} />
    </div>
  );
};

export default PayoutsChartComponent;
